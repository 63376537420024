import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import {
  FaChartLine,
  FaCheckDouble,
  FaRegLaughBeam,
  FaHandshake,
  FaSortAmountUp,
  FaMedal,
} from "react-icons/fa";
import ResponsiveEmbed from "react-responsive-embed";
import Columns from "../components/columns";
import Container from "../components/container";
import FunctionPageLayout from "../components/functionPageLayout";
import SEO from "../components/seo";
import * as styles from "./index.module.css";

export const query = graphql`
  {
    lukasz: file(relativePath: { eq: "lukasz-min.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    yt: file(relativePath: { eq: "yt.png" }) {
      childImageSharp {
        gatsbyImageData(width: 100, layout: CONSTRAINED)
      }
    }
    fb: file(relativePath: { eq: "fb.png" }) {
      childImageSharp {
        gatsbyImageData(width: 100, layout: CONSTRAINED)
      }
    }
    li: file(relativePath: { eq: "li.png" }) {
      childImageSharp {
        gatsbyImageData(width: 100, layout: CONSTRAINED)
      }
    }
    maria: file(relativePath: { eq: "maria.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    marta: file(relativePath: { eq: "marta-kazmierska.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    basia: file(relativePath: { eq: "basia-kedzierska.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    wojtek: file(relativePath: { eq: "wojciech-nosal.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    damian: file(relativePath: { eq: "damian.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    krzysztof: file(relativePath: { eq: "krzysztof-mn.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    pracownik1: file(relativePath: { eq: "pracownik1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    pracownik2: file(relativePath: { eq: "pracownik2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    filip: file(relativePath: { eq: "filip-osinski.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    kinga: file(relativePath: { eq: "wsparcie-kinga-napierala.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    stefan: file(relativePath: { eq: "stefan-domanski.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    krzys: file(relativePath: { eq: "krzysztof-lechowicz.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    asia: file(relativePath: { eq: "joanna-min.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    postac1: file(relativePath: { eq: "postac1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    iwan: file(relativePath: { eq: "iwan.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    kacperm: file(relativePath: { eq: "kacper-m.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    pawelk: file(relativePath: { eq: "pawel-k.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    jakubd: file(relativePath: { eq: "jakub-d.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    mateuszsz: file(relativePath: { eq: "mateusz-sz.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    weronikar: file(relativePath: { eq: "weronika-r.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    dominika: file(relativePath: { eq: "dominika-p.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    malwina: file(relativePath: { eq: "malwina.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    mikolajo: file(relativePath: { eq: "mikolaj.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
    michalsz: file(relativePath: { eq: "michal.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
        albert: file(relativePath: { eq: "albert.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                wiktoria: file(relativePath: { eq: "wiktoria.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
               sebastian: file(relativePath: { eq: "sebastian.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                malgorzata: file(relativePath: { eq: "malgorzata.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                  krystian: file(relativePath: { eq: "krystian.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                   bartosz: file(relativePath: { eq: "bartosz.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                lucyna: file(relativePath: { eq: "lucyna.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
            natalia: file(relativePath: { eq: "natalia-s.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
              sara: file(relativePath: { eq: "sara-z.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                  mikolajsz: file(relativePath: { eq: "mikolaj-sz.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                jakubp: file(relativePath: { eq: "jakub-p.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                szymon: file(relativePath: { eq: "szymon-g.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                  klaudia: file(relativePath: { eq: "klaudia.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                  daniel: file(relativePath: { eq: "daniel-b.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                sebastianw: file(relativePath: { eq: "sebastian-w.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                dominik: file(relativePath: { eq: "dominik-k.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                 adam: file(relativePath: { eq: "adam-k.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
               mateuszm: file(relativePath: { eq: "mateusz-m.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                mikolajw: file(relativePath: { eq: "mikolaj-w.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
             arkadiusz: file(relativePath: { eq: "arkadiusz-d.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
               slawomir: file(relativePath: { eq: "slawomir-d.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
              patryk: file(relativePath: { eq: "patryk1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
            katarzyna: file(relativePath: { eq: "katarzyna.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
            kacperl: file(relativePath: { eq: "kacper-l.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
             dawid: file(relativePath: { eq: "dawid.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
              wojciech: file(relativePath: { eq: "wojciech.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
             jakubj: file(relativePath: { eq: "jakub-j.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
               magda: file(relativePath: { eq: "magda-l.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                   manuela: file(relativePath: { eq: "manuela-s.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
                      jakubprz: file(relativePath: { eq: "jakub-prz.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 85, layout: CONSTRAINED)
      }
    }
  }
`;

const Ofirmie = ({ data }) => {
  return (
    <FunctionPageLayout title="Questy" headline="Informacje o naszej firmie">
      <SEO
        title="Producent oprogramowania dla firm - zadbaj o swój biznes"
        description="Producent oprogramowania dla firm ✅ Rozwiązania dedykowane • Systemy do zarządzania firmą • Systemy CRM • Platformy B2B • Systemy do serwisu"
        keywords={["Producent oprogramowania dla firm"]}
      />
      <h2 style={{
          paddingTop: 20,
        }}>
        Questy to czołowy polski producent oprogramowań dla biznesu<br /> oraz otwarty
        i&nbsp;wspierający zespół
      </h2>
      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p>
              <strong>
                {" "}
                Jesteśmy doświadczonym producentem i&nbsp;dostawcą oprogramowania
                dla&nbsp;biznesu.{" "}
              </strong>{" "}
              Od&nbsp;2002 roku działamy na polskim rynku B2B,
              <strong> wspierając klientów biznesowych rozwiązaniami informatycznymi</strong>
              , które&nbsp;pomagają realizować założone strategie i&nbsp;osiągać ambitne
              cele. Naszymi klientami są&nbsp;zarówno duże, rozpoznawalne firmy, jak
              i&nbsp;mniejsze, ale&nbsp;dynamicznie rozwijające&nbsp;się
              przedsiębiorstwa.</p> <p> Współpraca z naszymi
              klientami&nbsp;i&nbsp;partnerami pozwala nam stale się rozwijać i&nbsp;tworzyć 
              nowe rozwiązania&nbsp;dla firm. Obecnie oferujemy{" "}
              <strong>siedem różnych narzędzi dla przedsiębiorstw</strong>.
              Wśród nich znajdują się duże, dedykowane i&nbsp;komplementarne dla ERP
              rozwiązania, a&nbsp;także mniejsze, proste i&nbsp;posiadające wiele możliwości gotowe
                systemy, zintegrowane z&nbsp;wieloma&nbsp;programami handlowo-magazynowymi.
            </p>
          </div>

          <div className="text-block" style={{
          paddingTop: 30,
        }}>
            <ResponsiveEmbed
              src="https://www.youtube.com/embed/FAo-uF9t650"
              allowFullScreen
              ratio="16:9"
            />
          </div>
        </div>
      </Container>
      
      <h2
        style={{
          paddingBottom: 20,
        }}
      >
        Pracujemy z misją. Przyświeca nam wizja
      </h2>
      <center>
      <p style={{    
fontSize: 19,
lineHeight: "1.9rem",
paddingBottom: 5,
}}>
        <strong>Misja Questy: </strong>Dostarczamy rozwiązania informatyczne wspierające rozwój biznesu.
      </p>
        <p style={{    
fontSize: 19,
lineHeight: "1.9rem",
}}>
        <strong>Wizja Questy: </strong>Chcemy być najlepszym dostawcą rozwiązań informatycznych wykorzystywanych w&nbsp;organizacjach na całym świecie.
      </p>
      </center>


      <center>
        <h2 style={{
          paddingTop: 50,
          paddingBottom: 50,
        }}>W Questy wierzymy, że: </h2>{" "}
      </center>
      <Columns>
        <div>
          <center>
            <FaChartLine size="60px" />
            <p style={{
          paddingTop: 20,
        }}>
              Tworząc<strong> rozwiązania wysokiej jakości</strong>, przyczyniamy się do rozwoju naszych klientów.
            </p>
          </center>
        </div>
        <div>
          <center>
            <FaMedal size="60px" />
            <p style={{
          paddingTop: 20,
        }}>
              Miarą naszego sukcesu jest<strong> sukces klienta </strong>i&nbsp;z&nbsp;zaangażowaniem walczymy o jego osiągnięcie.
            </p>
          </center>
        </div>
        <div>
          <center>
            <FaHandshake size="60px" />
            <p style={{
          paddingTop: 20,
        }}>
              Nasi pracownicy i klienci<strong> są partnerami</strong>, którzy wspólnie budują długoterminową przyszłość.
            </p>
          </center>
        </div>
      </Columns>

      <Columns>
        <div  style={{
          paddingTop: 35,
        }}>
          <center>
            <FaCheckDouble size="60px" />
            <p style={{
          paddingTop: 20,
        }}>
              Dzięki<strong> współpracy </strong>osiągniemy więcej niż&nbsp;każdy&nbsp;z&nbsp;nas działając osobno.
            </p>
          </center>
        </div>
        <div  style={{
          paddingTop: 35,
        }}>
          <center>
            <FaRegLaughBeam size="60px" />
            <p style={{
          paddingTop: 20,
        }}>
            Inicjatywa i zaangażowanie są<strong> podstawą rozwoju </strong>i&nbsp;zasługują na uznanie.
            </p>
          </center>
        </div>
        <div  style={{
          paddingTop: 35,
        }}>
          <center>
            <FaSortAmountUp size="60px" />
            <p style={{
          paddingTop: 20,
        }}>
              <strong>Ciągłe doskonalenie </strong>metod działania oraz&nbsp;produktu&nbsp;jest dla nas czymś oczywistym.
            </p>
          </center>
        </div>
      </Columns>

      <h2
        style={{
          paddingTop: 45,
          paddingBottom: 20,
        }}
      >
        Jak działamy?
      </h2>

      <p>
        Do naszych kontrahentów należą liderzy wielu różnych branż.
        Współpracujemy między innymi z&nbsp;takimi organizacjami jak
        <strong>
          {" "}
          Grupa MTP, Interhandler, Metal-Fach, Kospel, Kompania Piwowarska czy Nespresso
        </strong>
        . Tworzone przez nas gotowe rozwiązania wykorzystywane są również w
        kilkuosobowych firmach.
      </p>
      <p>
        Oferowane przez Questy systemy{" "}
        <strong>
          każdego dnia są wykorzystywane i doceniane przez tysiące użytkowników
        </strong>
        . Wartość naszych produktów oraz korzyści płynące z ich wykorzystywania
        podkreślają klienci, partnerzy oraz rankingi.
      </p>
      <p>
        Projekty IT realizowane są w firmie Questy zgodnie z najlepszymi
        dostępnymi metodykami:
        <strong> PRINCE2 i SCRUM</strong>. Wsparcie systemów jest natomiast
        prowadzone w oparciu o <strong>praktyki ITIL</strong>. Pracownicy firmy
        Questy są również cenionymi analitykami wymagań, co potwierdza np.{" "}
        <strong>certyfikat REQB</strong>.
      </p>
      <p>
        <strong>
          Bezpieczeństwo danych jest naszym kluczowym priorytetem.
        </strong>{" "}
        O tym, jak wiele uwagi poświęcamy tej konkretnej kwestii, świadczą
        między innymi zdobyte przez
        <a href="https://questy.pl/certyfikat-iso-questy/">
          {" "}
          Questy certyfikaty{" "}
        </a>{" "}
        (ISO 27001), współpraca wyłącznie z najlepszymi dostawcami hostingu, a
        także rozbudowane mechanizmy zarządzania uprawnieniami w&nbsp;naszych
        systemach.
      </p>
      <h2
        style={{
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Partnerstwo z Questy
      </h2>
      <p>
        W Questy wierzymy, że dwie firmy są w stanie osiągnąć znacznie więcej,
        kiedy działają razem. Z&nbsp;należytym szacunkiem podchodzimy do wiedzy i&nbsp;doświadczenia 
        wszystkich naszych kontrahentów. Dlatego też stale dążymy
        do tego, by{" "}
        <strong>dostarczać najlepszą możliwą ofertę partnerską na rynku</strong>
        . Współpraca z nami opiera się na przejrzystych zasadach oraz na dużej
        swobodzie działania, a&nbsp;samą formę partnerstwa zawsze dopasowujemy do
        możliwości. Szczegóły dotyczące oferty partnerskiej znajdziesz na
        dedykowanej{" "}
        <a href="https://questy.pl/dla-partnera/">
          {" "}
          <strong>podstronie</strong>
        </a>
        .
      </p>
      <br />
      <h2
        style={{
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Dlaczego Questy to najlepszy wybór?
      </h2>
      <p>
        To proste.{" "}
        <strong>
          Oferowane przez nas oprogramowania integrują się z systemami
          handlowo-magazynowymi jak żadne inne dostępne na rynku rozwiązanie
        </strong>
        . W naszych produktach stawiamy przede wszystkim na elastyczność i
        skalowalność. Zgodnie z naszą filozofią, to oprogramowanie powinno
        dopasowywać się do potrzeb firmy – nie odwrotnie. Wraz ze wzrostem
        wymagań i oczekiwań dodajemy w&nbsp;naszych systemach kolejne możliwości i
        funkcje, dzięki czemu na żadnym etapie rozwoju nie zajdzie konieczność
        zmiany oprogramowania. Wszystkie rozwiązania Questy są względem siebie w
        pełni zgodne – jednocześnie możesz korzystać np. zarówno z&nbsp;CRMu, jak i
        programu do serwisu.
      </p>

      <br />

      <h2
        style={{
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Zespół Questy:
      </h2>

      <section className={styles.integrations}>
        <div className="container container--text">
          <br />
          <br />
          <br />
          <div className={styles.integrations__wrapper}>
            <div>
              <GatsbyImage
                image={data.lukasz.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Łukasz </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    CEO / PM / <br />
                    Business Architect{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>
            <div>
              <GatsbyImage image={data.maria.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Maria </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong> Finance and Controlling Manager </strong>{" "}
                </p>{" "}
              </center>
            </div>
            <div>
              <GatsbyImage
                image={data.wojtek.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Wojciech </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong> Business Unit <br />Manager </strong>{" "}
                </p>{" "}
              </center>
            </div>
             <div>
              <GatsbyImage image={data.albert.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Albert </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong> Business Development Manager </strong>{" "}
                </p>{" "}
              </center>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.integrations}>
        <div className="container container--text">
          <div className={styles.integrations__wrapper}>

                       <div>
              <GatsbyImage image={data.natalia.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Natalia </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong> Business Development Manager </strong>{" "}
                </p>{" "}
              </center>
            </div>
                       <div>
              <GatsbyImage image={data.magda.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Magdalena </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong> Business Development Manager </strong>{" "}
                </p>{" "}
              </center>
            </div>

            <div>
              <GatsbyImage image={data.marta.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Marta </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong> Business Development Manager </strong>{" "}
                </p>{" "}
              </center>
            </div>
            <div>
              <GatsbyImage image={data.basia.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Barbara </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong> Business Development Manager </strong>{" "}
                </p>{" "}
              </center>
            </div>
           
          </div>
        </div>
      </section>

      <section className={styles.integrations}>
        <div className="container container--text">
          <div className={styles.integrations__wrapper}>
            
                                  <div>
              <GatsbyImage
                image={data.michalsz.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Michał </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Business Development Manager
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

            <div>
              <GatsbyImage
                image={data.patryk.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Patryk </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Business Development Manager
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

                       <div>
              <GatsbyImage image={data.dominika.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Dominika </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    Business Development Manager
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

                        <div>
              <GatsbyImage
                image={data.sara.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Sara </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Personnel Administration <br /> Specialist
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>  
          </div>
        </div>
      </section>

      <section className={styles.integrations}>
        <div className="container container--text">
          <div className={styles.integrations__wrapper}>
                         
                      <div>
              <GatsbyImage image={data.kinga.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Kinga </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Project <br />
                    Manager{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

                         <div>
              <GatsbyImage
                image={data.damian.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Damian </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Project <br />
                    Manager
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>
             <div>
              <GatsbyImage
                image={data.pracownik1.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Krystian </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Project <br />
                    Manager{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

                        <div>
              <GatsbyImage
                image={data.mikolajw.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Mikołaj </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                   Project <br /> Manager{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>
            
          </div>
        </div>
      </section>

      <section className={styles.integrations}>
        <div className="container container--text">
          <div className={styles.integrations__wrapper}>
                      
                        <div>
              <GatsbyImage
                image={data.manuela.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Manuela </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                   Project <br /> Manager{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

           <div>
              <GatsbyImage
                image={data.jakubp.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Jakub </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Junior <br />
                   Project Manager{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div> 

         <div>
              <GatsbyImage
                image={data.katarzyna.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Katarzyna </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Junior <br />
                   Project Manager{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div> 

         <div>
              <GatsbyImage
                image={data.kacperl.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Kacper </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Junior <br />
                   Project Manager{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div> 

          </div>
        </div>
      </section>

            <section className={styles.integrations}>
        <div className="container container--text">
          <div className={styles.integrations__wrapper}>
               
                       <div>
              <GatsbyImage
                image={data.sebastianw.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Sebastian </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                   {" "}
                    Junior <br />
                   Project Manager{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

                                  <div>
              <GatsbyImage
                image={data.malwina.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Malwina </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Junior <br />
                   Project Manager{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

                                              <div>
              <GatsbyImage
                image={data.mikolajsz.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Mikołaj </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Junior <br />
                   Project Manager{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

            <div>
              <GatsbyImage image={data.filip.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Filip </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Engineer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

          </div>
        </div>
      </section>

      <section className={styles.integrations}>
        <div className="container container--text">
          <div className={styles.integrations__wrapper}>
            
          <div>
              <GatsbyImage
                image={data.stefan.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Stefan </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Engineer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

            <div>
              <GatsbyImage image={data.krzys.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Krzysztof </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Engineer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>   

                      <div>
              <GatsbyImage
                image={data.pracownik2.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Piotr </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Engineer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

                      <div>
              <GatsbyImage
                image={data.dawid.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Dawid </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                  Software <br />
                    Engineer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

          </div>
        </div>
      </section>

      <section className={styles.integrations}>
        <div className="container container--text">
          <div className={styles.integrations__wrapper}>
           
          <div>
              <GatsbyImage
                image={data.wiktoria.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Wiktoria </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    User Interface <br />
                    Designer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

            <div>
              <GatsbyImage image={data.iwan.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Iwan </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

                       <div>
              <GatsbyImage
                image={data.pawelk.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Paweł </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

            <div>
              <GatsbyImage
                image={data.jakubd.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Jakub </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>
             
          </div>
        </div>
      </section>

      <section className={styles.integrations}>
        <div className="container container--text">
          <div className={styles.integrations__wrapper}>
           
           <div>
              <GatsbyImage
                image={data.mateuszsz.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Mateusz </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

             <div>
              <GatsbyImage
                image={data.weronikar.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Weronika </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

          <div>
              <GatsbyImage
                image={data.sebastian.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Sebastian </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

            <div>
              <GatsbyImage
                image={data.malgorzata.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Małgorzata </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>
              
          </div>
        </div>
      </section>

      <section className={styles.integrations}>
        <div className="container container--text">
          <div className={styles.integrations__wrapper}>
            
          <div>
              <GatsbyImage
                image={data.krystian.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Krystian </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

             <div>
              <GatsbyImage
                image={data.bartosz.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Bartosz </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>  

           <div>
              <GatsbyImage
                image={data.szymon.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Szymon </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

                                <div>
              <GatsbyImage
                image={data.klaudia.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Klaudia </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

          </div>
        </div>
      </section>

            <section className={styles.integrations}>
        <div className="container container--text">
          <div className={styles.integrations__wrapper}>
          
           <div>
              <GatsbyImage
                image={data.daniel.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Daniel </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

                                                      <div>
              <GatsbyImage
                image={data.dominik.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Dominik </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

                                <div>
              <GatsbyImage
                image={data.adam.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Adam </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

                    <div>
              <GatsbyImage image={data.mateuszm.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Mateusz </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

          </div>
        </div>
      </section>

      <section className={styles.integrations}>
        <div className="container container--text">
          <div className={styles.integrations__wrapper}>   

                       <div>
              <GatsbyImage image={data.arkadiusz.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Arkadiusz </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

             <div>
              <GatsbyImage image={data.slawomir.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Sławomir </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

                         <div>
              <GatsbyImage image={data.wojciech.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Wojciech </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

                                       <div>
              <GatsbyImage image={data.jakubj.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Jakub </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>            

          </div>
        </div>
      </section>

            <section className={styles.integrations}>
        <div className="container container--text">
          <div className={styles.integrations__wrapper}>

                                     <div>
              <GatsbyImage image={data.jakubprz.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Jakub </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Software <br />
                    Developer{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>
                  
           <div>
              <GatsbyImage image={data.asia.childImageSharp.gatsbyImageData} />
              <center>
                {" "}
                <p> Joanna </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    Marketing <br />
                    Manager{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>
          
            <div>
              <GatsbyImage
                image={data.mikolajo.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Mikołaj </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                  Marketing <br />
                    Manager{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>
             
                         <div>
              <GatsbyImage
                image={data.lucyna.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Lucyna </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                  Marketing <br />
                    Specialist{" "}
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

          </div>
        </div>
      </section>

        <section className={styles.integrations}>
        <div className="container container--text">
          <div className={styles.integrations__wrapper}>

          <div> </div>

                      <div>
              <GatsbyImage
                image={data.postac1.childImageSharp.gatsbyImageData}
              />
              <center>
                {" "}
                <p> Twoje imię </p>{" "}
              </center>

              <center>
                {" "}
                <p>
                  {" "}
                  <strong>
                    {" "}
                    <Link to="/kariera/"><strong>Dołącz do naszego <br />
                    zespołu!{" "}</strong></Link>
                  </strong>{" "}
                </p>{" "}
              </center>
            </div>

            <div> </div>

          </div>
        </div>
      </section>


    </FunctionPageLayout>
  );
};

export default Ofirmie;
